import Promise from 'es6-promise';
import Deferred from 'es6-deferred';
import BrowserInfo from 'ThinkBase/lib/browser.info';
import LazyLoad from 'ThinkBase/lib/lazyload';
import CustomEventHandler from "ThinkBase/lib/custom.event.handler";
import GeneralFunctions from "ThinkBase/lib/general.functions";

window.Deferred = Deferred;
window.BrowserInfo = BrowserInfo;
window.LazyLoad = LazyLoad;
window.CustomEventHandler = CustomEventHandler;
window.GeneralFunctions = GeneralFunctions;

window.axios = null;
window.bootstrap = null;
window.moment = null;
window._ = null;

window.librariesDeferred = {
    bootstrap: new Deferred(),
    axios: new Deferred(),
    moment: new Deferred(),
    lodash: new Deferred()
};

import('axios').then((axiosComponent) => {
    window.axios = axiosComponent.default;
    window.librariesDeferred.axios.resolve();
});

import('lodash').then((lodashComponent) => {
    window._ = lodashComponent.default;
    window.librariesDeferred.lodash.resolve();
});

import('popper.js').then((Popper) => {
    window.Popper = Popper.default;
    import('bootstrap').then((bootstrapComponent) => {
        window.bootstrap = bootstrapComponent;
        window.librariesDeferred.bootstrap.resolve();
    });
});

import("@fortawesome/fontawesome-pro/js/light.min").then(() => {
    import("@fortawesome/fontawesome-pro/js/fontawesome.min");
});
import('moment').then((momentComponent) => {
    window.moment = momentComponent.default;
    window.librariesDeferred.moment.resolve();
});
