import _ from 'lodash';
export default class ControlFunctions {
    static getElement(selector){
        if (typeof selector === 'string'){
            return document.querySelector(selector);
        }
        return selector;
    }
    static createOption(value, text){
        let opt = document.createElement("option");
        opt.value = value;
        opt.text = text;
        return opt;
    }
    static clearSelect(cmb){
        cmb = ControlFunctions.getElement(cmb);
        cmb.value = '';
        cmb.options.length = 0;
    }
    static fillSelect(cmb, data, empty = ''){
        cmb = ControlFunctions.getElement(cmb);
        if (Object.entries(data).length > 0){
            if (empty !== ''){
                cmb.options.add(ControlFunctions.createOption('', empty));
            }
            _.each(data,(text, value) => {
                cmb.options.add(ControlFunctions.createOption(value, text));
            });
        }
        else {
            ControlFunctions.clearSelect(cmb);
        }
    }
}
