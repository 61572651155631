import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);
const browserNames = {
	IE: "Internet Explorer",
	Chrome: "Chrome",
	Firefox: "Firefox"
};
/**
 * Wrapper of Bowser functions in case that we change the browser detection library on the future
 */
export default class BrowserInfo {
	static getBrowserName() {
		return browser.getBrowserName();
	}

	static getPlatformType() {
		return browser.getPlatformType(true);
	}

	static is(browserName){
		return browser.isBrowser(browserName, true);
	}

	static isInternetExplorer(){
		return BrowserInfo.is(browserNames.IE);
	}

	static isFirefox(){
		return BrowserInfo.is(browserNames.Firefox);
	}

	static isChrome(){
		return BrowserInfo.is(browserNames.Chrome);
	}
}
