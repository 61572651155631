import BrowserInfo from "./browser.info";

export default class CustomEventHandler {
	/**
	 * Dispatch a custom event through the document
	 * @param eventName - Name of the event
	 * @param data - Data to be passed on the event
	 * @param bubbles - boolean to trigger bubble effect
	 */
	static dispatch(eventName, data, bubbles){
		let params = {};
		if (typeof bubbles === 'undefined'){
			//Set default behavior to bubbles = true
			bubbles = true;
		}
		params.bubbles = bubbles;
		if (typeof data !== 'undefined'){
			params.detail = data;
		}

		let customEvent = new CustomEvent(eventName, params);
		document.dispatchEvent(customEvent);
	}

	/**
	 * Assign an event listener on the document and execute a callback when is dispatched
	 * @param eventName - Name of the event
	 * @param callback - Function to be executed
	 * @param serializeParams - If is true then the callback is called with spread operator on the param
	 * is to cover callbacks with style func(param1, param2 etc) otherwise the object is passed directly to callback
	 */
	static addEventListener(eventName, callback, serializeParams){
		if (typeof callback === 'function'){
			document.addEventListener(eventName,(e) => {
				let data = (e.detail)?e.detail:{};
				if (serializeParams === true){
					if (BrowserInfo.isInternetExplorer()){
						return callback.apply(null, data);
					}
					return callback(...data);
				}
				return callback(data);
			});
		}
		else {
			console.warn('CustomEventHandler: Callback must be a function');
		}
	}
}
